import React from 'react';
import classes from "./block1.module.css";
import Button from "../button/button";
import video1 from '../../video/1.mov'
import video2 from '../../video/2.mov'
import video3 from '../../video/3.mov'
import MediaQuery from "react-responsive";

const Block1 = ({popup}) => {
    const textHeader = [
        {id:0, text:'Рекламные ролики</br> любой продукции'},
        {id:1, text:'Выставки'},
        {id:2, text:'Выпускные'},
        {id:3, text:'Свадьбы'}
    ]
    const textHeader2 = [
        {id:4, text:'Отели, рестораны и любые предприятия сегмента HoReCa'},
        {id:5, text:'Конференции и форумы'},
        {id:6, text:'Модельные видео'},
        {id:7, text:'Дни Рождения'}
    ]

    return (
        <div className={classes._} id='top'>
            <div className={classes.block}>
                <MediaQuery minWidth={825}>
                    <div className={classes.header}>
                        <div className={classes.headerBlock}>
                            {textHeader.map(item=>
                                <div className={classes.headerText} key={item.id}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="3" height="3" style={{minWidth:'3px',minHeight:'3px'}} viewBox="0 0 3 3" fill="none">
                                        <circle cx="1.5" cy="1.5" r="1.5" fill="white"/>
                                    </svg>
                                    <div dangerouslySetInnerHTML={{__html: item.text}}></div>
                                </div>
                            )}
                        </div>
                        <div className={classes.headerBlock}>
                            {textHeader2.map(item=>
                                <div className={classes.headerText} key={item.id}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="3" height="3" style={{minWidth:'3px',minHeight:'3px'}} viewBox="0 0 3 3" fill="none">
                                        <circle cx="1.5" cy="1.5" r="1.5" fill="white"/>
                                    </svg>
                                    <div dangerouslySetInnerHTML={{__html: item.text}}></div>
                                </div>
                            )}
                        </div>
                    </div>
                </MediaQuery>
                <div>
                    <MediaQuery maxWidth={1159}>
                        <div className={classes.blockImage2}>
                            <div className={classes.blockImage}>
                                <svg className={classes.block2ImageFon} xmlns="http://www.w3.org/2000/svg" width="330" height="330" viewBox="0 0 330 330" fill="none">
                                    <path d="M164.509 0.00158496L329.018 164.511L164.509 329.02L158.5 329.02L154.228 318.738L143.946 308.456L123.382 287.892L82.2548 246.765L0.000323269 164.511L82.2548 82.2561L123.382 41.1288L143.946 20.5652L154.228 10.2834L158.5 1.54148e-05L164.509 0.00158496Z" fill="url(#paint0_linear_227_1101)"/>
                                    <defs>
                                        <linearGradient id="paint0_linear_227_1101" x1="246.762" y1="82.2545" x2="82.2533" y2="246.764" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#F7E4B5"/>
                                            <stop offset="0.209457" stopColor="#D0B265"/>
                                            <stop offset="0.574381" stopColor="#AD8D39"/>
                                            <stop offset="0.941322" stopColor="#664A1F"/>
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <div className={classes.block2ImageBlock}>
                                    <video loop
                                           muted
                                           playsInline
                                           autoPlay
                                           className={classes.photo1}>
                                        <source
                                            src={video1}
                                            type="video/mp4"
                                        />
                                    </video>
                                </div>
                            </div>
                            <div className={classes.blockImage}>
                                <svg className={classes.block2ImageFon} xmlns="http://www.w3.org/2000/svg" width="330" height="330" viewBox="0 0 330 330" fill="none">
                                    <path d="M164.509 0.00158496L329.018 164.511L164.509 329.02L158.5 329.02L154.228 318.738L143.946 308.456L123.382 287.892L82.2548 246.765L0.000323269 164.511L82.2548 82.2561L123.382 41.1288L143.946 20.5652L154.228 10.2834L158.5 1.54148e-05L164.509 0.00158496Z" fill="url(#paint0_linear_227_1101)"/>
                                    <defs>
                                        <linearGradient id="paint0_linear_227_1101" x1="246.762" y1="82.2545" x2="82.2533" y2="246.764" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#F7E4B5"/>
                                            <stop offset="0.209457" stopColor="#D0B265"/>
                                            <stop offset="0.574381" stopColor="#AD8D39"/>
                                            <stop offset="0.941322" stopColor="#664A1F"/>
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <div className={classes.block2ImageBlock}>
                                    <video loop
                                           muted
                                           playsInline
                                           autoPlay
                                           className={classes.photo1}>
                                        <source
                                            src={video2}
                                            type="video/mp4"
                                        />
                                    </video>
                                </div>
                            </div>
                            <div className={classes.blockImage}>
                                <svg className={classes.block2ImageFon} xmlns="http://www.w3.org/2000/svg" width="330" height="330" viewBox="0 0 330 330" fill="none">
                                    <path d="M164.509 0.00158496L329.018 164.511L164.509 329.02L158.5 329.02L154.228 318.738L143.946 308.456L123.382 287.892L82.2548 246.765L0.000323269 164.511L82.2548 82.2561L123.382 41.1288L143.946 20.5652L154.228 10.2834L158.5 1.54148e-05L164.509 0.00158496Z" fill="url(#paint0_linear_227_1101)"/>
                                    <defs>
                                        <linearGradient id="paint0_linear_227_1101" x1="246.762" y1="82.2545" x2="82.2533" y2="246.764" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#F7E4B5"/>
                                            <stop offset="0.209457" stopColor="#D0B265"/>
                                            <stop offset="0.574381" stopColor="#AD8D39"/>
                                            <stop offset="0.941322" stopColor="#664A1F"/>
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <div className={classes.block2ImageBlock}>
                                    <video loop
                                           muted
                                           playsInline
                                           autoPlay
                                           className={classes.photo1}>
                                        <source
                                            src={video3}
                                            type="video/mp4"
                                        />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </MediaQuery>
                    <div className={classes.block2Text}>
                        <div className={classes.text1}>Моменты<br/>на красной<br/>ковровой дорожке</div>
                        <Button text={'забронировать дату'} click={popup}/>
                    </div>
                    <MediaQuery minWidth={1160}>
                        <div className={classes.block2Images}>
                        <div>
                            <svg className={classes.block2ImageFon} xmlns="http://www.w3.org/2000/svg" width="330" height="330" viewBox="0 0 330 330" fill="none">
                                <path d="M164.509 0.00158496L329.018 164.511L164.509 329.02L158.5 329.02L154.228 318.738L143.946 308.456L123.382 287.892L82.2548 246.765L0.000323269 164.511L82.2548 82.2561L123.382 41.1288L143.946 20.5652L154.228 10.2834L158.5 1.54148e-05L164.509 0.00158496Z" fill="url(#paint0_linear_227_1101)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_227_1101" x1="246.762" y1="82.2545" x2="82.2533" y2="246.764" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#F7E4B5"/>
                                        <stop offset="0.209457" stopColor="#D0B265"/>
                                        <stop offset="0.574381" stopColor="#AD8D39"/>
                                        <stop offset="0.941322" stopColor="#664A1F"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                            <div className={classes.block2ImageBlock}>
                                <video loop
                                       muted
                                       playsInline
                                       autoPlay
                                       //onMouseMove={(ev)=> ev.target.play()}
                                       //onMouseOut={(ev)=> ev.target.pause()}
                                       className={classes.photo1}>
                                    <source
                                        src={video1}
                                        type="video/mp4"
                                    />
                                </video>
                            </div>
                        </div>
                        <div className={classes.block2Images2}>
                            <svg className={classes.block2ImageFon2} xmlns="http://www.w3.org/2000/svg" width="330" height="330" viewBox="0 0 330 330" fill="none">
                                <path d="M117.59 0.362314L234.263 117.035L117.59 233.708H111.594L110.298 226.416L103.006 219.123L88.4218 204.539L59.2536 175.371L0.917343 117.035L59.2537 58.6986L88.4218 29.5305L103.006 14.9464L110.298 7.65435L111.597 0.362515L117.59 0.362314Z" fill="url(#paint0_linear_248_242)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_248_242" x1="176.972" y1="59.7444" x2="62.3918" y2="174.324" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#F7E4B5"/>
                                        <stop offset="0.209457" stopColor="#D0B265"/>
                                        <stop offset="0.574381" stopColor="#AD8D39"/>
                                        <stop offset="0.941322" stopColor="#664A1F"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                            <div className={classes.block2ImageBloc2}>
                                <video loop
                                       muted
                                       playsInline
                                       autoPlay
                                       //onMouseMove={(ev)=> ev.target.play()}
                                       //onMouseOut={(ev)=> ev.target.pause()}
                                       className={classes.photo2}>
                                    <source
                                        src={video2}
                                        type="video/mp4"
                                    />
                                </video>
                            </div>
                        </div>
                        <div className={classes.block2Images3}>
                            <svg className={classes.block2ImageFon3} xmlns="http://www.w3.org/2000/svg" width="330" height="330" viewBox="0 0 330 330" fill="none">
                                <path d="M137.705 0.248317L274.983 137.526L137.705 274.803L131.706 274.803L133.415 270.514L129.125 266.224L120.546 257.644L103.386 240.484L69.0664 206.165L0.427639 137.526L69.0664 68.8871L103.386 34.5677L120.546 17.408L129.125 8.82816L131.689 0.248421L137.705 0.248317Z" fill="url(#paint0_linear_248_244)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_248_244" x1="207.574" y1="70.1174" x2="72.7589" y2="204.933" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#F7E4B5"/>
                                        <stop offset="0.209457" stopColor="#D0B265"/>
                                        <stop offset="0.574381" stopColor="#AD8D39"/>
                                        <stop offset="0.941322" stopColor="#664A1F"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                            <div className={classes.block2ImageBloc3}>
                                <video loop
                                       muted
                                       playsInline
                                       autoPlay
                                       //onMouseMove={(ev)=> ev.target.play()}
                                       //onMouseOut={(ev)=> ev.target.pause()}
                                       className={classes.photo3}>
                                    <source
                                        src={video3}
                                        type="video/mp4"
                                    />
                                </video>
                            </div>
                        </div>
                    </div>
                    </MediaQuery>
                    <div className={classes.block3}>
                        <div className={classes.block3Text}>
                            <div className={classes.block3Line}></div>
                            Мы – команда профессиональных творцов, которые отказываются снимать скучные и однотипные ролики по методичке.</div>
                        <div className={classes.block3Text}>
                            <div className={classes.block3Line}></div>
                            Мы легко адаптируемся к меняющимся запросам, превращаем работу с клиентами в увлекательное путешествие и вместе получаем от него истинное удовольствие.</div>
                        <div className={classes.block3Text}>
                            <div className={classes.block3Line}></div>
                            Снимаем с любовью и без пересветов.</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Block1;