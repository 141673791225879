import React, {useState} from 'react';
import classes from "./block7.module.css";
import fon from '../../image/fon4.png'
import Input from "../input/input";
import Button from "../button/button";
import MediaQuery from "react-responsive";
import {new_client} from "../../api";

const Block7 = () => {
    const [date, setDate] = useState('')
    const [format, setFormat] = useState('')
    const [phone, setPhone] = useState('')
    const [name, setName] = useState('')

    async function click(){
        await new_client(name, phone, date,null, format)
        setPhone('')
        setDate('')
        setName('')
        setFormat('')
    }

    return (
        <div className={classes._} id='price'>
            <div className={classes.block}>
                <div className={classes.fonBlock}>
                    <img src={fon} className={classes.fon} alt={''}/>
                    <div className={classes.fon2}></div>
                </div>
                <div className={classes.form}>
                    <div className={classes.text1}>Оставить заявку</div>
                    <div className={classes.text2}>Получите расчёт стоимости, ответив на несколько вопросов. <MediaQuery minWidth={430}><br/></MediaQuery>Заполните форму и мы свяжемся с Вами.</div>
                    <MediaQuery minWidth={430}>
                        <Input placeholder={'Дата проведения'} value={date} setValue={setDate} style={{marginTop:'30px'}}/>
                        <Input placeholder={'Формат мероприятия?'} value={format} setValue={setFormat} style={{marginTop:'10px'}}/>
                        <Input placeholder={'Ваше Имя'} value={name} setValue={setName} style={{marginTop:'20px'}}/>
                        <Input placeholder={'Ваш телефон'} value={phone} setValue={setPhone} style={{marginTop:'10px'}}/>
                    </MediaQuery>
                    <MediaQuery maxWidth={429}>
                        <Input placeholder={'Дата проведения'} value={date} setValue={setDate} style={{marginTop:'30px', width:'calc(100% - 60px)'}}/>
                        <Input placeholder={'Формат мероприятия?'} value={format} setValue={setFormat} style={{marginTop:'10px', width:'calc(100% - 60px)'}}/>
                        <Input placeholder={'Ваше Имя'} value={name} setValue={setName} style={{marginTop:'20px', width:'calc(100% - 60px)'}}/>
                        <Input placeholder={'Ваш телефон'} value={phone} setValue={setPhone} style={{marginTop:'10px', width:'calc(100% - 60px)'}}/>
                    </MediaQuery>
                    <div className={classes.button}>
                        <Button text={'рассчитать сумму'} click={click}/>
                        <div className={classes.text3}>Нажимая на кнопку, я даю согласие на обработку своих персональных данных.</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Block7;